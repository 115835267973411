import "./App.css";
import "./App_mobile.css";
import config from "./config.json";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
  requestTTS,
  requestPoem,
  requestImg,
  initById,
  share,
} from "./services/backend.jsx";
import ParticleBackground from "react-particle-backgrounds";

import WelcomePage from "./modules/WelcomePage";
import KeywordPage from "./modules/KeywordPage";
import LoadingPage from "./modules/LoadingPage";
import PoemPage from "./modules/PoemPage";
import EditorPage from "./modules/EditorPage";
import CardPage from "./modules/CardPage";
import SharePopup from "./modules/SharePopup";

import particleSettings from "./particleSettings.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StartingPage from "./modules/StartingPage";
import CreatingPage from "./modules/CreatingPage";

function App(props) {
  const settings = particleSettings;

  const [keyphrase, setKeyphrase] = useState("");
  const [content, setContent] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [id, setId] = useState("");
  const [design, setDesign] = useState(0);
  const [page, setPage] = useState(-1); //-1: loading; 0-welcome; 1-keyword; 2-loading; 3-poem; 4-editor; 5-creating...; 6-card
  const opacityPages = [1, 3, 4, 5];
  const [sharePopup, setSharePopup] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [audioBuffer, setAudioBuffer] = useState();

  let params = useParams();
  const navigate = useNavigate();

  // const loadingTime = 5000;
  const loadingTime = 2500;

  useEffect(() => {
    if (params.id) {
      init(params.id);
    } else {
      if (page === -1) {
        cacheImages();
        setTimeout(() => {
          setPage(0);
          setTimeout(() => {
            window.scrollTo(500, 500);
          }, 200);
        }, loadingTime);
      }
      if (page === 5) {
        cacheCardImages();
        setTimeout(() => setPage(6), 1500);
      }
    }
  }, [params.id, page]);

  useEffect(() => {
    audio.pause();
    audio.currentTime = 0;
    console.log("page changed!");
  }, [setPage]);

  function cacheImages() {
    const imgs = [
      "assets/frame_mobile.svg",
      "assets/title_mobile.svg",
      "assets/share-mobile.svg",
      "assets/hyveLogo.svg",
      "assets/shareYourChristmas.svg",
      "assets/star.svg",
      "assets/creating.svg",
      "assets/creatingPostcard.svg",
      "assets/hyveLogo.svg",
      "assets/button.svg",
      "assets/button-hover.svg",
      "assets/title.svg",
      "assets/frame.svg",
      "assets/starting.svg",
      "assets/poemFrame.svg",
      "assets/happyChristmas.svg",
      "assets/theme-deer@2x.png",
      "assets/theme-gatsby@2x.png",
      "assets/theme-forest@2x.png",
      "assets/share.svg",
    ];
    imgs.forEach((pic) => (new Image().src = pic.fileName));
  }

  function cacheCardImages() {
    let imgs = [];
    if (isMobile)
      imgs = [
        "assets/bg-deer-mobile-bot.webp",
        // "assets/bg-deer-mobile-bot.png",
        "assets/bg-gatsby-mobile-bot.webp",
        // "assets/bg-gatsby-mobile-bot.png",
        "assets/bg-forest-mobile-bot.webp",
        // "assets/bg-forest-mobile-bot.png",
        "assets/bg-deer-mobile-top.webp",
        // "assets/bg-deer-mobile-top.png",
        "assets/bg-gatsby-mobile-top.webp",
        // "assets/bg-gatsby-mobile-top.png",
        "assets/bg-forest-mobile-top.webp",
        // "assets/bg-forest-mobile-top.png",
      ];
    else
      imgs = [
        "assets/bg-deer@2x.jpg",
        "assets/bg-gatsby@2x.jpg",
        "assets/bg-forest@2x.jpg",
      ];
    imgs.forEach((pic) => (new Image().src = pic.fileName));
  }

  async function init(id) {
    const data = await initById(id).catch((err) => {
      navigate("/");
      return;
    });
    setKeyphrase(data.keyphrase);
    setContent(data.content);
    setDesign(data.design);

    const base64String = data.audioBuffer;
    setAudioBuffer(base64String);

    function base64ToBuffer(base64) {
      const binaryString = atob(base64);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

    const decodedBuffer = base64ToBuffer(base64String);

    const blob = new Blob([decodedBuffer], { type: "audio/mp3" });
    const audioSrc = URL.createObjectURL(blob);
    const newAudio = new Audio(audioSrc);

    newAudio.addEventListener("ended", () => {
      setPlaying(false);
    });
    setAudio(newAudio);
    setImgUrl(config.backendUrl + data.imgUrl + ".png");
    setPage(6);
  }

  function handleGoButtonClick(e) {
    getContent();
  }

  async function getContent() {
    setPage(2);

    const [poem, imgUrl] = await Promise.all([
      requestPoem(keyphrase),
      requestImg(keyphrase),
    ]);

    new Image().src = imgUrl;
    new Image().src = "assets/globe-dark.png";

    setContent(poem);

    const response = await requestTTS(poem);
    const arrayBuffer = await response.arrayBuffer();
    console.log("arrayBuffer");
    console.log(arrayBuffer);

    function bufferToBase64(buffer) {
      const binaryString = new Uint8Array(buffer).reduce(
        (acc, byte) => acc + String.fromCharCode(byte),
        ""
      );
      return btoa(binaryString);
    }

    const base64String = bufferToBase64(arrayBuffer);

    setAudioBuffer(base64String);

    // console.log(base64String);

    // function base64ToBuffer(base64) {
    //   const binaryString = atob(base64);
    //   const bytes = new Uint8Array(binaryString.length);
    //   for (let i = 0; i < binaryString.length; i++) {
    //     bytes[i] = binaryString.charCodeAt(i);
    //   }
    //   return bytes.buffer;
    // }

    // const decodedBuffer = base64ToBuffer(base64String);

    const blob = new Blob([arrayBuffer], { type: "audio/mp3" });
    const audioSrc = URL.createObjectURL(blob);
    const newAudio = new Audio(audioSrc);

    newAudio.addEventListener("ended", () => {
      setPlaying(false);
    });
    setAudio(newAudio);
    setImgUrl(imgUrl);
    setPage(3);
  }

  function handleInputchange(e) {
    setKeyphrase(e.target.value);
  }

  function handleNewPoemClick(e) {
    setPageAndStopAudio(0);
    setKeyphrase("");
    setId("");
    navigate("/");
  }

  function handlePlayPause(e) {
    if (!playing) audio.play();
    else audio.pause();

    setPlaying(!playing);
  }

  function setPageAndStopAudio(page) {
    audio.pause();
    audio.currentTime = 0;
    setPage(page);
  }

  function handleShare(e) {
    console.log("sharing...");
    setSharePopup(true);
    const data = {
      content: content,
      keyphrase: keyphrase,
      imgUrl: imgUrl,
      audioBuffer: audioBuffer,
      design: design.toString(),
    };
    console.log(data);
    if (!id) shareAndGetId(data);
  }

  async function shareAndGetId(data) {
    let id = await share(data).catch((err) => {
      return;
    });
    setId(id);
  }

  function renderPage() {
    switch (page) {
      case -1: {
        return <StartingPage />;
      }
      case 0:
        return <WelcomePage onNext={() => setPage(1)} />;
      // return <SoundCheckPage onNext={() => setPage(1)} />;
      case 1:
        return (
          <KeywordPage
            keyphrase={keyphrase}
            handleInputchange={handleInputchange}
            onNext={handleGoButtonClick}
          />
        );
      case 2:
        return <LoadingPage />;
      case 3:
        return (
          <PoemPage
            keyphrase={keyphrase}
            content={content}
            imgUrl={imgUrl}
            onNext={() => setPageAndStopAudio(4)}
            onNew={handleNewPoemClick}
            onPlayPause={handlePlayPause}
            playing={playing}
          />
        );
      case 4:
        return (
          <EditorPage
            keyphrase={keyphrase}
            content={content}
            onNext={() => setPage(5)}
            setDesign={setDesign}
          />
        );
      case 5:
        return <CreatingPage />;
      case 6:
        return (
          <CardPage
            keyphrase={keyphrase}
            design={design}
            content={content}
            onNew={handleNewPoemClick}
            onShare={handleShare}
            imgUrl={imgUrl}
            onPlayPause={handlePlayPause}
            playing={playing}
          />
        );
      default:
        break;
    }
  }

  function getParticleClass() {
    if (opacityPages.includes(page)) return "Particles o-10";
    else return "Particles";
  }

  return (
    <div className="App">
      <ToastContainer theme="colored" />
      {sharePopup ? (
        <SharePopup id={id} onClose={() => setSharePopup(false)} />
      ) : null}
      <div className="App-header">
        <div className="Particles"></div>
        <ParticleBackground
          className={getParticleClass()}
          settings={settings}
        />
        {renderPage()}
        <div className="Privacy">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.hyve.net/en/privacy-statement/"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
