import config from "../config.json";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpointChat = "api/gen/chat";
// const endpointChat = "api/gen/test";
const endpointTTS = "api/gen/tts";
const endpointImg = "api/gen/img";
const endpointLinks = "api/links/";

// get poem from backend
export async function requestPoem(keyphrase) {
  if (!keyphrase) keyphrase = " ";
  console.log("keyphrase: " + keyphrase);
  const prefix = "A rhyming poem with exactly 12 lines about Christmas and ";
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ prompt: prefix + keyphrase }),
  };
  let res = null;

  res = await fetch(config.backendUrl + endpointChat, requestOptions).catch(
    (e) => {
      toast.error("backend connection failed");
      throw Error("backend connection failed");
    }
  );

  if (res.status === 200) {
    const obj = await res.json();
    // return cropPoem(obj.data);
    return obj.data;
  } else {
    toast.error("chat connection failed");
    throw Error("chat connection failed");
  }
}

function cropPoem(poem) {
  let cutPoem = poem.match(/(?:^.*$\n?){1,16}/gm);
  console.log(cutPoem);
  cutPoem = cutPoem[0].slice(2);
  console.log("----");
  console.log(cutPoem);
  return cutPoem;
}

// get shared content and keyphrase from backend
export async function initById(id) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(
    config.backendUrl + endpointLinks + id,
    requestOptions
  ).catch((e) => {
    toast.error("backend conncetion failed");
    throw Error("backend connection failed");
  });

  const data = await res.json();
  return data;
}

// share content and keyphrase and receive id
export async function share(body) {
  console.log("sharing!!");
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    // body: body,
  };
  const res = await fetch(
    config.backendUrl + endpointLinks,
    requestOptions
  ).catch((e) => {
    toast.error("backend conncetion failed");
    throw Error("backend connection failed");
  });
  const data = await res.json();
  return data.id;
}

export async function requestTTS(prompt) {
  if (!prompt) prompt = " ";
  console.log("prompt: " + prompt);
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ prompt: prompt }),
  };
  let res = null;

  res = await fetch(config.backendUrl + endpointTTS, requestOptions).catch(
    (e) => {
      toast.error("backend connection failed");
      throw Error("backend connection failed");
    }
  );

  if (res.status === 200) {
    const result = await res;
    console.log("result: " + result);
    return await result;
  } else {
    toast.error("tts connection failed");
    throw Error("tts connection failed");
  }
}

export async function requestImg(prompt) {
  if (!prompt) prompt = " ";
  const postfix = " and christmas";
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ prompt: prompt + postfix }),
  };
  let res = null;

  res = await fetch(config.backendUrl + endpointImg, requestOptions).catch(
    (e) => {
      toast.error("backend connection failed");
      throw Error("backend connection failed");
    }
  );

  if (res.status === 200) {
    const data = await res.json();
    return data.imgUrl;
  } else {
    toast.error("img connection failed");
    throw Error("img connection failed");
  }
}
