import ParticleBackground from "react-particle-backgrounds";
import particleSettings from "../particleSettings-globe.json";

const CardPage = (props) => {
  const settings = particleSettings;
  const { keyphrase, content, design, playing, imgUrl, onPlayPause } = props;

  function getFooterClass() {
    return "Card-Footer-Container Card-Footer-Container-" + design;
  }

  return (
    <>
      <div className={"ThemedFrame Theme-" + design}>
        {/* <div className="HyveLogo"></div> */}
        {/* <div className="Star"></div> */}
        <div className="CardContent">
          {/* <div className="Poem-Title">{keyphrase}</div> */}
          <div className="happyChristmas" />
          {/* <div className="Poem-Text">{content}</div> */}
          <div className="snow-globe-container">
            <div className="play-button-container">
              <button
                className="primaryBtn mt-20"
                name="play"
                onClick={props.onPlayPause}
              >
                <p>{playing ? "Pause" : "Play"}</p>
              </button>
            </div>
            <ParticleBackground
              className={"Particles-globe"}
              settings={settings}
            ></ParticleBackground>
            <img className="snow-globe" alt="" />
            <img className="snow-globe-gradient" alt="" />
            <img className="snow-globe-img" src={imgUrl} alt="" />
          </div>
          {content !== "" && content !== "\n\nCreating poetry..." && (
            <div className={getFooterClass()}>
              <div className="spacer-40" />
              <div className="Poem-Footer">
                This snow globe was brought to you by AI <br />
                and HYVE - The Innovation Company
              </div>
              <div
                className="Logo-Footer"
                onClick={() =>
                  window.open("https://www.hyve.net/en/", "_blank")
                }
              ></div>
              <div className="Btn-Container-transparent mobile-only">
                <div className="card-buttons">
                  <button
                    className="primaryBtn"
                    name="start"
                    onClick={props.onNew}
                  >
                    <p>New Poem</p>
                  </button>
                  <button
                    className="primaryBtn"
                    name="start"
                    onClick={props.onShare}
                  >
                    <p>Share</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="Btn-Container-transparent desktop-only">
        <div className="card-buttons">
          <button className="primaryBtn" name="start" onClick={props.onNew}>
            <p>New Poem</p>
          </button>
          <button className="primaryBtn" name="start" onClick={props.onShare}>
            <p>Share</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default CardPage;
