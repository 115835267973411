import ParticleBackground from "react-particle-backgrounds";
import particleSettings from "../particleSettings-globe.json";

const PoemPage = (props) => {
  const settings = particleSettings;
  const { content, keyphrase, imgUrl, playing, onPlayPause } = props;
  return (
    <>
      <div
        className="HyveLogo"
        onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
      ></div>
      <div className="PoemFrame">
        <div className="Star mobile-only"></div>
        <div className="CardContent">
          <div>
            {/* <div className="Poem-Title">{keyphrase}</div> */}
            <div className="happyChristmas-Poem mobile-only" />
            <div className="bg-grey">
              {/* <div className="Poem-Text">{content}</div> */}
              <div className="snow-globe-container">
                <div className="play-button-container">
                  <button
                    className="primaryBtn mt-20"
                    name="play"
                    onClick={props.onPlayPause}
                  >
                    <p>{playing ? "Pause" : "Play"}</p>
                  </button>
                </div>
                <ParticleBackground
                  className={"Particles-globe"}
                  settings={settings}
                ></ParticleBackground>
                <img className="snow-globe" alt="" />
                <img className="snow-globe-gradient" alt="" />
                <img className="snow-globe-img" src={imgUrl} alt="" />
              </div>
            </div>
          </div>
        </div>
        {content !== "" && content !== "\n\nCreating poetry..." && (
          <div className="Poem-Footer-Container bg-grey">
            <div className="Poem-Footer">
              This snow globe was brought to you by AI <br />
              and HYVE - The Innovation Company
            </div>
            <div
              className="Logo-Footer desktop-only"
              onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
            ></div>
          </div>
        )}
        <div className="Btn-Container mobile-only">
          <button
            className="primaryBtn mt-20"
            name="start"
            onClick={props.onNew}
          >
            <p>Repeat</p>
          </button>
          <button
            className="primaryBtn mt-20"
            name="start"
            onClick={props.onNext}
          >
            <p>Create Card</p>
          </button>
        </div>
      </div>
      <div className="Btn-Container desktop-only">
        <button className="primaryBtn" name="start" onClick={props.onNew}>
          <p>Repeat</p>
        </button>
        <button className="primaryBtn" name="start" onClick={props.onNext}>
          <p>Create Card</p>
        </button>
      </div>
      <div className="spacer-40 bg-grey"></div>
    </>
  );
};

export default PoemPage;
