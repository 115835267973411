const StartingPage = () => {
  return (
    <div className="fadeOut">
      <div className="Loading-Bar"></div>
      <div className="Starting"></div>
    </div>
  );
};

export default StartingPage;
