import InputField from "./InputField";

const KeywordPage = (props) => {
  return (
    <>
      <div
        className="HyveLogo"
        onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
      ></div>
      <div className="Frame">
        <div className="Star"></div>
        <div className="ShareYourChristmas"></div>
        <div className="CardContent">
          <div className="Menu-Headline-Keyword">
            Choose words for your magic snow globe
          </div>
          {/* <div className="mt-50"> */}
          <InputField
            value={props.keyphrase}
            onInputChange={props.handleInputchange}
            onPressEnter={() => {
              props.onNext();
            }}
          />
          {/* </div> */}
        </div>
      </div>
      <div className="MobileCover"></div>
      <div className="Btn-Container">
        <button
          className="primaryBtn"
          name="start"
          onClick={props.onNext}
          disabled={props.keyphrase === "" ? true : false}
        >
          <p>Next</p>
        </button>
      </div>
      <div className="clear-keyword"></div>
      <div className="spacer-40 bg-grey"></div>
    </>
  );
};

export default KeywordPage;
