import { useState, useRef } from "react";
import config from "./../config.json";
import { CornerLoader } from "react-craftify-spinners";

const SharePopup = (props) => {
  const [headline, setHeadline] = useState("Click to copy the link");
  const [clicked, setClicked] = useState(false);
  const textAreaRef = useRef(null);
  const { id } = props;

  function copyToClipboard(e) {
    if (!clicked) {
      console.log("copied!");
      textAreaRef.current.select();
      document.execCommand("copy");
      e.target.focus();
      setHeadline("Copied!");
      setClicked(true);
    }
  }

  function clickCloseHandler(e) {
    if (e.target.className === "Popup-Container") props.onClose();
  }

  function inputValue() {
    if (clicked) {
      return "copied!";
    } else {
      return id ? config.frontendUrl + id : "";
    }
  }

  return (
    <div className="Popup-Container" onClick={clickCloseHandler}>
      <div className="Popup-Box">
        {/* <div className="Menu-Headline">{headline}</div> */}
        <div className="Close-Share" onClick={props.onClose}></div>
        {id ? (
          <input
            ref={textAreaRef}
            onClick={copyToClipboard}
            readOnly
            className="InputField-Share"
            value={inputValue()}
          ></input>
        ) : (
          <CornerLoader color="#be965d55" className="loader"></CornerLoader>
        )}
      </div>
    </div>
  );
};

export default SharePopup;
