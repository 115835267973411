const CreatingPage = () => {
  return (
    <>
      <div
        className="HyveLogo"
        onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
      ></div>
      <div className="CenteredBox">
        <div className="StarAnimated"></div>
        <div className="CreatingCard"></div>
      </div>
      <div className="AI-footer">
        This poem is brought to you by AI
        <br />
        and HYVE - the innovation company
      </div>
    </>
  );
};

export default CreatingPage;
