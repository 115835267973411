const WelcomePage = (props) => {
  return (
    <div className="fadeIn">
      <div
        className="HyveLogo"
        onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
      ></div>
      <div className="Frame">
        <div className="Star"></div>
        <div className="CardContent">
          <div className="Title"></div>
        </div>
      </div>
      <div className="MobileCover"></div>
      <div className="Btn-Container">
        <button className="primaryBtn" name="start" onClick={props.onNext}>
          <p>Let's do magic</p>
        </button>
      </div>
      <div className="clear-welcome"></div>
      <div className="spacer-40 bg-grey"></div>
    </div>
  );
};

export default WelcomePage;
