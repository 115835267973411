import React, { Component } from "react";

// class InputField extends Component {
const InputField = (props) => {
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      props.onPressEnter();
    }
  };
  return (
    <input
      className="InputField"
      type="promt"
      id="name"
      name="name"
      required
      minLength="2"
      maxLength="30"
      size="10"
      onChange={props.onInputChange}
      value={props.value}
      placeholder="e.g. cleaning robot"
      autoComplete="off"
      onKeyDown={keyHandler}
    ></input>
  );
};

export default InputField;
