import { useState } from "react";

const EditorPage = (props) => {
  const [selection, setSelection] = useState(0);

  const select = (index) => {
    setSelection(index);
    props.setDesign(index);
    props.onNext();
  };

  const getClasses = (index) => {
    return selection === index ? "Pattern-Item Active" : "Pattern-Item";
  };

  return (
    <>
      <div
        className="HyveLogo"
        onClick={() => window.open("https://www.hyve.net/en/", "_blank")}
      ></div>
      <div className={"PoemFrame"}>
        <div className="StarEditor"></div>
        <div className="CardContent">
          <div>
            <div className="Title-Short mobile-only" />
            <div className="Menu-Headline">Choose a theme</div>
            <div className="Pattern-Container">
              <div className={getClasses(1)} onClick={() => select(1)}>
                <div className="Pattern Pattern1"></div>

                {/* <div className="Pattern-Name">design 1</div> */}
              </div>
              <div className={getClasses(2)} onClick={() => select(2)}>
                <div className="Pattern Pattern2"></div>

                {/* <div className="Pattern-Name">design 2</div> */}
              </div>
              <div className={getClasses(3)} onClick={() => select(3)}>
                <div className="Pattern Pattern3"></div>

                {/* <div className="Pattern-Name">design 3</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-40 bg-grey"></div>

      {/* <button
        className="primaryBtn"
        name="start"
        onClick={props.onNext}
        disabled={selection === 0}
      >
        <p>Create Postcard</p>
      </button> */}
    </>
  );
};

export default EditorPage;
